<template>
  <JoszakiAutocomplete
    v-model="searchValue"
    :placeholder="$t('navbar.search.placeholder')"
    :items="items"
    :formatter="(item) => item.name"
    :loading="loading"
    group-field="group"
    true-autocomplete-mode
    @input="onSearchInput"
    @select="onItemSelected"
    @focus="$emit('focus')"
  >
    <template #empty>
      <span>
        {{
          searchValue.length > 3
            ? $t("common.noResults")
            : $t("navbar.search.minLengthMessage")
        }}
      </span>
    </template>
  </JoszakiAutocomplete>
</template>

<script>
import { ref, useContext, useRouter } from "@nuxtjs/composition-api";
import SEARCH_QUERY from "./search.graphql";
import { debounce } from "~/helpers/debounce";

export default {
  name: "AutocompleteSearch",
  setup() {
    const { $query, localePath, i18n, $logger } = useContext();
    const router = useRouter();
    const searchValue = ref("");
    const items = ref([]);
    const loading = ref(false);

    const onSearchInput = debounce(async function (search) {
      if (search.length < 3) {
        items.value = [];
        return;
      }
      loading.value = true;
      try {
        const { professionals, professions, professionTasks } = await $query(
          SEARCH_QUERY,
          { search: search.toLowerCase() }
        );

        items.value = [
          ...professionals.people.map((p) => ({
            ...p,
            to: localePath({
              name: "professionalPage",
              params: { professionalSeoName: p.seoName },
            }),
            group: i18n.t("navbar.search.professionals"),
          })),
          ...professions.map((p) => ({
            ...p,
            to: localePath({
              name: "professionPage",
              params: { profession: p.seoName },
            }),
            group: i18n.t("navbar.search.professions"),
          })),
          ...professionTasks.map((p) => ({
            ...p,
            to: localePath({
              name: "prices",
              params: { professionTask: p.seoName },
            }),
            group: i18n.t("navbar.search.prices"),
          })),
        ];
      } catch (e) {
        $logger.error(e);
      } finally {
        loading.value = false;
      }
    }, 500);

    const onItemSelected = (item) => {
      router.push(item.to);
      items.value = [];
      searchValue.value = "";
    };

    return {
      searchValue,
      items,
      loading,
      onSearchInput,
      onItemSelected,
    };
  },
};
</script>
