<template>
  <JoszakiInput
    ref="input"
    v-model="email"
    type="email"
    name="email"
    icon-left="envelope"
    :placeholder="$t('newTender.placeHolder.email')"
    :reserve-error-space="reserveErrorSpace"
    :validation-state="newTender.v$.value.email"
    enterkeyhint="next"
    @keypress.enter="$emit('next', 'email')"
    @blur="onBlur"
  >
    <template #label="{ failed }">
      <p
        class="font-bold text-lg text-typo-primary"
        :class="{
          '!text-error': failed,
        }"
      >
        {{ $t("newTender.formData.email") }}
      </p>
      <p
        v-if="showEducation"
        class="font-semibold text-sm mb-1 opacity-80 text-typo-primary"
        :class="{
          '!text-error': failed,
        }"
      >
        {{ $t("newTender.education.email") }}
      </p>
    </template>
  </JoszakiInput>
</template>

<script>
import { useNewTender } from "~/composables/tender/useNewTender";

export default {
  props: {
    showEducation: {
      type: Boolean,
      default: true,
    },
    reserveErrorSpace: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const newTender = useNewTender();
    return {
      newTender,
      email: newTender.email,
    };
  },
  methods: {
    onBlur(e) {
      this.newTender.saveToStorage();
      this.$emit("blur", e);
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>
