<template>
  <div>
    <JoszakiInput
      ref="input"
      v-model="name"
      name="name"
      icon-left="user"
      :placeholder="$t('newTender.placeHolder.name')"
      :reserve-error-space="reserveErrorSpace"
      :validation-state="newTender.v$.value.name"
      enterkeyhint="next"
      @keypress.enter="$emit('next', 'name')"
      @blur="onBlur"
    >
      <template #label="{ failed }">
        <p
          class="font-bold text-lg text-typo-primary"
          :class="{
            '!text-error': failed,
          }"
        >
          {{ $t("newTender.formData.name") }}
        </p>
      </template>
    </JoszakiInput>
  </div>
</template>

<script>
import { useNewTender } from "~/composables/tender/useNewTender";

export default {
  props: {
    reserveErrorSpace: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const newTender = useNewTender();
    return {
      newTender,
      name: newTender.name,
    };
  },
  methods: {
    onBlur(e) {
      this.newTender.saveToStorage();
      this.$emit("blur", e);
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>
