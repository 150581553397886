<template>
  <div>
    <p
      class="font-bold text-lg text-typo-primary"
      :class="{
        '!text-error': newTender.v$.value.phone.$error,
      }"
    >
      {{ $t("newTender.formData.mobile") }}
    </p>
    <p
      v-if="showEducation"
      class="font-semibold text-sm mb-1 opacity-80 text-typo-primary"
      :class="{
        '!text-error': newTender.v$.value.phone.$error,
      }"
    >
      {{ $t("newTender.education.mobile") }}
    </p>
    <JoszakiPhoneInput
      ref="input"
      v-model="phone"
      name="phone"
      :validation-state="newTender.v$.value.phone"
      enterkeyhint="done"
      drop-down-position="top"
      @keypress.enter="$emit('next', 'phone')"
    >
    </JoszakiPhoneInput>
  </div>
</template>

<script>
import { useNewTender } from "~/composables/tender/useNewTender";

export default {
  props: {
    showEducation: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const newTender = useNewTender();

    return {
      newTender,
      phone: newTender.phone,
    };
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>
