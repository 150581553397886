var render = function render(){var _vm=this,_c=_vm._self._c;return _c('JoszakiModal',{attrs:{"open":_vm.modalOpen,"title":_vm.mate
      ? _vm.$t('newTender.header.titleWithPerson')
      : _vm.$t('newTender.header.title'),"size":"lg","loading":_vm.loading},on:{"confirm":_vm.submit,"cancel":_vm.close,"close":_vm.close},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',{staticClass:"font-semibold text-base md:font-bold md:text-lg text-primary"},[(_vm.mate)?[_vm._v(" "+_vm._s(_vm.$t("newTender.header.titleWithPerson"))+" ")]:[_vm._v(" "+_vm._s(_vm.$t("newTender.header.title"))+" ")]],2)]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"w-full flex justify-center items-center relative z-[2] gap-2 md:gap-8"},[(_vm.mate)?_c('div',{staticClass:"flex-shrink-0 w-10 h-10 md:w-20 md:h-20"},[_c('div',{staticClass:"rounded-full h-full w-full overflow-hidden"},[_c('PersonPictureSet',{staticClass:"w-10 h-10 md:w-20 md:h-20 object-cover",attrs:{"person-id":_vm.mate.id}})],1)]):_vm._e(),_c('div',{staticClass:"flex-1"},[_c('p',{staticClass:"font-bold text-2xl mb-2 md:font-extrabold md:text-3xl text-primary-dark md:mb-4"},[(_vm.mate)?[_vm._v(" "+_vm._s(_vm.mate.name)+" ")]:_vm._e()],2),(
            !_vm.mate && _vm.prefilled && _vm.$ab.isVariant('new-tender-2', 'variant2')
          )?_c('p',{staticClass:"font-semibold text-base md:font-bold md:text-lg text-primary"},[_vm._v(" "+_vm._s(_vm.$t("newTender.header.secondaryTitleWithCityAndProf", { city: _vm.newTender.city?.value?.name?.toLowerCase(), profession: _vm.newTender.profession?.value?.objective?.toLowerCase(), }))+" "),_c('br'),_c('a',{staticClass:"text-primary-dark",on:{"click":_vm.newTender.modifyCityAndProfession}},[_vm._v("("+_vm._s(_vm.$t("newTender.modifyCityAndProfession"))+")")])]):_vm._e(),(
            !_vm.mate && (!_vm.prefilled || _vm.$ab.isVariant('new-tender-2', 'variant1'))
          )?_c('p',{staticClass:"font-semibold text-base md:font-bold md:text-lg text-primary"},[_vm._v(" "+_vm._s(_vm.$t("newTender.header.secondaryTitle"))+" ")]):_vm._e()])]),_c('form',{staticClass:"grid gap-4",attrs:{"id":"new-tender"}},[_c('FieldName',{ref:"name",on:{"next":_vm.next}}),_c('FieldDescription',{ref:"jobDescription",on:{"next":_vm.next}}),(!_vm.prefilled || _vm.$ab.isVariant('new-tender-2', 'variant1'))?[_c('FieldProfession',{ref:"profession",on:{"next":_vm.next}}),_c('FieldCity',{ref:"city",on:{"next":_vm.next}})]:_vm._e(),_c('FieldEmail',{ref:"email",on:{"next":_vm.next}}),_c('FieldPhone',{ref:"phone",on:{"next":_vm.next}})],2)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"flex flex-col md:flex-row gap-4 md:gap-8 justify-center items-center"},[_c('div',{staticClass:"flex-1"},[_vm._v(" "+_vm._s(_vm.$t("newTender.termsOfUSe.prompt"))+" "),_c('NuxtLink',{attrs:{"to":_vm.localePath({ name: 'terms' }),"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("newTender.termsOfUSe.linkText")))])],1),_c('JoszakiButton',{attrs:{"type":"success","loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t("newTender.submit"))+" ")])],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }