import { defineStore } from "pinia";
import { useAdminProfessionalStore } from "~/stores/admin/professional";
import { useProfessionalStore } from "~/stores/professional";

const ADMIN_OPEN_KEY = "joszaki/admin-open";

export const useAdminStore = defineStore("admin", {
  state: () => ({
    panelOpen: false,
    showPictureDialog: false,
  }),
  getters: {},
  actions: {
    togglePanel() {
      const newState = !this.panelOpen;
      localStorage.setItem(ADMIN_OPEN_KEY, newState);
      this.panelOpen = newState;
      const adminProfessionalStore = useAdminProfessionalStore();
      if (!adminProfessionalStore.privateDataLoaded) {
        adminProfessionalStore.loadPrivateData();
      }
    },
    restorePanelState() {
      if (
        localStorage.getItem(ADMIN_OPEN_KEY) === "true" &&
        useProfessionalStore().person
      ) {
        this.panelOpen = true;
        const adminProfessionalStore = useAdminProfessionalStore();
        adminProfessionalStore.loadPrivateData();
      }
    },
    openPictureDialog() {
      this.showPictureDialog = true;
    },
    closePictureDialog() {
      this.showPictureDialog = false;
    },
  },
});
