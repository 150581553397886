<template>
  <div>
    <p
      class="font-bold text-typo-primary mb-1"
      :class="{
        '!text-error': newTender.v$.value.profession.$error,
      }"
    >
      {{ $t("newTender.formData.profession") }}
    </p>
    <p
      v-if="showEducation"
      class="font-semibold text-sm mb-1 opacity-80 text-typo-primary"
      :class="{
        '!text-error': newTender.v$.value.profession.$error,
      }"
    >
      {{ $t("newTender.education.profession") }}
    </p>
    <AutocompleteProfession
      ref="input"
      v-model="newTender.profession.value"
      :placeholder="$t('searchBox.professionPlaceholder')"
      @select="
        selectProfession($event);
        // Emit is hard to make work with setup in vue2
        $emit('next', 'profession');
      "
    />
  </div>
</template>

<script>
import AutocompleteProfession from "~/components/_refactored/autocomplete/AutocompleteProfession.vue";
import { useNewTender } from "~/composables/tender/useNewTender";

export default {
  components: {
    AutocompleteProfession,
  },
  props: {
    showEducation: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const newTender = useNewTender();

    const selectProfession = (profession) => {
      newTender.profession.value = profession;
      newTender.saveToStorage();
    };

    return {
      newTender,
      selectProfession,
    };
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>
