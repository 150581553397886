var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"font-bold text-typo-primary mb-1",class:{
      '!text-error': _vm.newTender.v$.value.city.$error,
    }},[_vm._v(" "+_vm._s(_vm.$t("newTender.formData.city"))+" ")]),(_vm.showEducation)?_c('p',{staticClass:"font-semibold text-sm mb-1 opacity-80 text-typo-primary",class:{
      '!text-error': _vm.newTender.v$.value.city.$error,
    }},[_vm._v(" "+_vm._s(_vm.$t("newTender.education.city"))+" ")]):_vm._e(),_c('AutocompleteCity',{ref:"input",attrs:{"placeholder":_vm.$t('searchBox.cityPlaceholder')},on:{"select":function($event){_vm.selectCity($event);
      // Emit hard to make work with setup in vue2
      _vm.$emit('next', 'city');}},model:{value:(_vm.newTender.city.value),callback:function ($$v) {_vm.$set(_vm.newTender.city, "value", $$v)},expression:"newTender.city.value"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }