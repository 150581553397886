<template>
  <div>
    <p
      class="font-bold text-typo-primary mb-1"
      :class="{
        '!text-error': newTender.v$.value.city.$error,
      }"
    >
      {{ $t("newTender.formData.city") }}
    </p>
    <p
      v-if="showEducation"
      class="font-semibold text-sm mb-1 opacity-80 text-typo-primary"
      :class="{
        '!text-error': newTender.v$.value.city.$error,
      }"
    >
      {{ $t("newTender.education.city") }}
    </p>
    <AutocompleteCity
      ref="input"
      v-model="newTender.city.value"
      :placeholder="$t('searchBox.cityPlaceholder')"
      @select="
        selectCity($event);
        // Emit hard to make work with setup in vue2
        $emit('next', 'city');
      "
    />
  </div>
</template>

<script>
import AutocompleteCity from "~/components/_refactored/autocomplete/AutocompleteCity.vue";
import { useNewTender } from "~/composables/tender/useNewTender";

export default {
  components: {
    AutocompleteCity,
  },
  props: {
    showEducation: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const newTender = useNewTender();

    function selectCity(city) {
      newTender.city.value = city;
      newTender.saveToStorage();
    }

    return {
      newTender,
      selectCity,
    };
  },
  methods: {
    focus() {
      this.$refs.input.focusInput();
    },
  },
};
</script>
