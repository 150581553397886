import { useRoute } from "@nuxtjs/composition-api";

/**
 * Returns whether the noBrand query parameter is active.
 * ~/plugins/noBrand.js will propagate the noBrand query parameter to all routes.
 */
export function useNoBrandActive() {
  const route = useRoute();

  return route.value.query?.noBrand === "true" ?? false;
}
