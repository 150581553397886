import { useContext, computed } from "@nuxtjs/composition-api";

export function usePopularProfessions() {
  const { store, $helpers, localePath, i18n } = useContext();

  const professions = store.getters["data/popularProfessions"];

  const professionMenuItems = computed(() => {
    const items = professions.map((profession) => ({
      id: `profession-${profession.seoName}`,
      text: $helpers.capitalize(profession.name),
      icon: "arrow-right",
      to: localePath({
        name: "professionPage",
        params: { profession: profession.seoName },
      }),
    }));
    items.unshift({
      id: "profession-all",
      icon: "list-ol",
      text: i18n.t("navbar.professions.all"),
      to: localePath({ name: "professionsLandingPage" }),
    });

    return items;
  });

  return { professions, professionMenuItems };
}
