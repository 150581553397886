<template>
  <JoszakiTextArea
    ref="input"
    v-model="jobDescription"
    :label="$t('newTender.formData.jobDescription')"
    :placeholder="$t('newTender.placeHolder.jobDescription')"
    :reserve-error-space="reserveErrorSpace"
    :validation-state="newTender.v$.value.jobDescription"
    @focus="$emit('focus')"
    @blur="onBlur"
  />
</template>

<script>
import { useNewTender } from "~/composables/tender/useNewTender";

export default {
  props: {
    reserveErrorSpace: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    try {
      const newTender = useNewTender();

      return {
        newTender,
        jobDescription: newTender.jobDescription,
      };
    } catch (err) {
      console.error("fieldDescription setup error", err);
      return {};
    }
  },
  methods: {
    onBlur(e) {
      this.newTender.saveToStorage();
      this.$emit("blur", e);
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>
